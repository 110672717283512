import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from "react-bootstrap/Container"
import logo from '../../content/assets/logo.png';
import "@fontsource/karla";

const Layout = ({ isHomePage, children }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="global-wrapper" data-is-root-path={isHomePage}>
      <header className="global-header">
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container>
            <img
              src={logo}
              height="120em"
              className="d-inline-block align-top logo"
              alt="Progressive Politics NI logo"
            />
            <Navbar.Brand href="/">Progressive Politics NI</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
              <Nav>
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/news">News</Nav.Link>
                <Nav.Link href="/about">About</Nav.Link>
                <Nav.Link href="/charter">Charter</Nav.Link>
                <Nav.Link href="/candidates">Candidates</Nav.Link>
                <Nav.Link href="/candidates2">Candidates2</Nav.Link>
                <Nav.Link href="/candidates3">Candidates3</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <main>
        {children}
      </main>

      <footer>
        © {new Date().getFullYear()} - Progressive Politics NI
      </footer>
    </div>
  )
}

export default Layout
